<template>
  <div>
    <top-bar v-if="isAuthenticated"></top-bar>

    <div class="main-div">
      <router-view />
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import axios from "axios";
export default {
  components: {
    TopBar,
  },
  data() {
    return {
      isChecking: false,
    };
  },
  methods: {
    checkAuth() {
      if (localStorage.getItem("glc")) {
        this.$store.state.isAuthenticated = true;
        this.$store.state.StudentId = localStorage.getItem("glc");
      } else {
        this.$store.state.isAuthenticated = false;
        this.$store.state.StudentId = "";
      }
    },
    async checkMessages() {
      this.isChecking = true;
      var resp = "";
      await axios
        .post("datalist.php", {
          optn: "MessageCount",
          glc: this.$store.state.StudentId,
        })
        .then(function (response) {
          if (response.data.Errorsts == false) {
            resp = response.data.List[0].msgcount;
          }
        });
      this.isChecking = false;
      this.$store.state.MsgCount = resp;
    },
  },
  computed: {
    isAuthenticated() {
      var auth = false;
      var autologin = localStorage.getItem("autologin");
      if (autologin == null) {
        autologin = false;
      }
      if (this.$store.state.isAuthenticated == true && autologin == true) {
        auth = false;
      }

      if (this.$store.state.isAuthenticated == true && autologin == false) {
        auth = true;
      }

      return auth;
    },
  },
  mounted() {
    this.checkAuth();
    // setInterval(() => {
    //   if (this.isChecking == false) {
    //     this.checkMessages();
    //   }
    // }, 1500);
  },
};
</script>

<style>
body {
  margin: 0px;
  padding: 0px;
  clear: both;
  background-color: #6a4c93;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0px;
  padding: 0px;
}
.main-div {
  position: relative;
  top: 55px;
}
</style>
