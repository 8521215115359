<template>
  <div class="close-btn">
    <router-link to="/dashboard">
      <div class="closebtn"><span class="txt">X</span></div>
      <!-- <span class="fas fa-times-circle"></span> -->
    </router-link>
  </div>
</template>

<style scoped>
.close-btn {
  position: relative;
  justify-content: right;
  right: 5px;
  float: right;
  cursor: pointer;
}

.close-btn a {
  position: relative;
  color: var(--closebtnhover-color);
  font-size: 20px;
  text-decoration: none;
  top: -3px;
}

.close-btn a:hover {
  color: var(--closebtn-color);
}

.closebtn {
  position: relative;
  top: 2px;
  border: 2px solid #f53939;
  border-radius: 25px;
  left: 5px;
  width: 22px;
  height: 22px;
  text-align: center;
  background-color: #f53939;
  color: white;
  margin: 0 auto !important;
}

.closebtn .txt {
  position: relative;
  font-size: 14px;
  top: -6px;
}

.closebtn:hover {
  box-shadow: 0px 1px 2px 0px red;
  background-color: crimson;
  color: white;
}

@media only screen and (max-width: 600px) {
  .closebtn .txt {
    position: relative;
    top: -3px;
  }
  .closebtn .txt {
    position: relative;
    font-size: 14px;
    top: -4px;
  }
}
</style>
