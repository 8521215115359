<template>
  <div class="close-btn" @click="goBack()">
    <span class="fas fa-circle">
      <span class="fa-solid fa-times"></span>
    </span>
    <!-- <div class="closebtn"><span class="txt">X</span></div> -->
  </div>
</template>

<script>
export default {
  emits: ["closeit"],
  methods: {
    goBack() {
      this.$emit("closeit");
    },
  },
};
</script>
<style scoped>
.fa-times {
  position: absolute;
  color: white;
  font-size: 20px;
  left: 8px;
  top: 4px;
}

.fa-circle {
  position: relative;
  top: -2px;
  font-size: 28px;
  font-weight: bolder;
  color: #f53939;
  border-radius: 50%;
  float: right;
}
.fa-circle:hover {
  box-shadow: 0px 1px 2px 0px red;
  color: crimson;
}
.close-btn {
  position: relative;
  justify-content: right;
  right: 5px;
  float: right;
  cursor: pointer;
  top: -2px;
}

/*.closebtn {
  position: relative;
  top: 2px;
  border: 2px solid #f53939;
  border-radius: 25px;
  left: 5px;
  width: 22px;
  height: 22px;
  text-align: center;
  background-color: #f53939;
  color: white;
  margin: 0 auto !important;
}

.closebtn .txt {
  position: relative;
  font-size: 14px;
  top: -6px;
}

.closebtn:hover {
  box-shadow: 0px 1px 2px 0px red;
  background-color: crimson;
  color: white;
} */

@media only screen and (max-width: 600px) {
  .fa-times {
    position: absolute;
    color: white;
    font-size: 16px;
    left: 8px;
    top: 4px;
  }

  .fa-circle {
    position: relative;
    top: -6px;
    right: -8px;
    font-size: 26px;
    font-weight: bolder;
    color: #f53939;
    border-radius: 50%;
    float: right;
  }

  /* .closebtn .txt {
    position: relative;
    top: -3px;
  }
  .closebtn .txt {
    position: relative;
    font-size: 14px;
    top: -4px;
  } */
}
</style>
