<!-- @format -->
<template>
  <div class="loginpage-div">
    <message-box ref="msgbox"></message-box>
    <div class="outer-div">
      <div class="login-div">
        <div class="inner-div">
          <img src="../assets/complogo.png" class="logo" alt="Logo" />

          <card-loader
            v-show="isChecking"
            :LoaderText="'Authenticating..'"
          ></card-loader>

          <form class="login-form" v-show="!isChecking">
            <label class="tocenter login-lbl">Login</label>
            <span class="errmsg" :class="errclass" v-if="err">{{
              errmsg
            }}</span>
            <label for="uname">E-mail:</label>
            <input
              type="email"
              name="uname"
              class="form-control"
              v-model="uname"
              maxlength="100"
              autocomplete="off"
              autofocus
              v-on:keydown.enter="nextBox($event.target)"
            />

            <label for="upass">Password:</label>
            <input
              type="password"
              name="upass"
              class="form-control"
              v-model="upass"
              maxlength="20"
              autocomplete="off"
              v-on:keydown.enter="nextBox($event.target)"
            />
            <button class="btn btn-success" type="button" @click="login()">
              Login
            </button>
            <button class="btn btn-primary" type="button" @click="signup()">
              Create New Account
            </button>
            <router-link to="/forgotpass" class="forgotpass"
              ><i class="fa-solid fa-key"></i>&nbsp;Forgot Password?
            </router-link>
            <router-link to="/help" class="help">
              <i class="fa-solid fa-circle-question"></i>&nbsp;Help & FAQ
            </router-link>
            <router-link to="/privacypolicy" class="help">
              Privacy Policy
            </router-link>
          </form>
        </div>
        <div class="devcontact">
          <img src="../assets/kvr.png" class="devlogo" />
          <a href="https://kvrtechnologies.in/">KVR Technologies</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import CardLoader from "../components/CardLoader.vue";
import MyFun from "../js/MyFun";
export default {
  name: "LoginForm",
  emits: ["login-btn", "sign-up"],
  components: { CardLoader },
  data() {
    return {
      uname: "",
      upass: "",
      err: false,
      errmsg: "",
      errclass: "",
      isChecking: false,
      pageName: "users.php",
    };
  },
  methods: {
    async login() {
      if (this.uname.trim() != "" && this.upass.trim() != "") {
        if (MyFun.checkEmail(this.uname) && MyFun.checkPass(this.upass)) {
          this.isChecking = true;
          this.err = false;
          this.errclass = "warnmsg";
          this.errmsg = "Authenticating...";
          var response;
          await axios
            .post(this.pageName, {
              optn: "Checkuser",
              uname: this.uname,
              upass: this.upass,
            })
            .then(function (resp) {
              response = resp.data;
            });
          this.isChecking = false;

          if (response.Errorsts === false) {
            if (response.List.length > 0) {
              this.errclass = "donemsg";
              this.errmsg = "Welcome!" + this.uname;

              var Listdata = response.List[0];
              var comp = JSON.parse(response.company);
              this.$store.state.companyName = comp[0].cname;
              localStorage.setItem("glc", Listdata.glc);
              localStorage.setItem("uid", this.uname);
              localStorage.setItem("companyname", comp[0].cname);
              this.$store.state.userId = this.uname;
              this.$store.state.isAuthenticated = true;
              this.$store.state.StudentId = Listdata.glc;
              this.$router.replace("/main");
            } else {
              this.err = true;
              this.errclass = "dangermsg";
              this.errmsg = "Invalid Username/Password!";
            }
          } else {
            this.err = true;
            this.errclass = "dangermsg";
            this.errmsg = "Unable to Login! Something Went Wrong!";
            console.log(response.ErrorMsg);
          }
        } else {
          this.err = true;
          this.errclass = "dangermsg";
          this.errmsg = "Invalid E-Mail or Password!";
        }
      } else {
        this.err = true;
        this.errmsg = "Please Fill User Name/Password!";
        this.errclass = "dangermsg";
      }
    },
    signup() {
      this.$router.push("/signup");
    },
    nextBox(ele) {
      const currentIndex = Array.from(ele.form.elements).indexOf(ele);
      ele.form.elements
        .item(
          currentIndex < ele.form.elements.length - 1 ? currentIndex + 1 : 0
          // currentIndex + 1
        )
        .focus();
    },
  },
  created() {
    this.uname = this.$route.query.uname;
    this.upass = this.$route.query.upass;
    if (this.uname != undefined) {
      localStorage.clear();
      this.$store.state.isAuthenticated = false;
      localStorage.setItem("autologin", true);
      this.login();
    }
  },
};
</script>

<style>
@import "../css/base.css";
@import "../css/formcontrol.css";
</style>

<style scoped>
.loginpage-div {
  height: calc(100vh - 120px);
}
.login-lbl {
  font-size: 22px;
  text-decoration: underline;
}
.outer-div {
  position: relative;
  margin: 0px auto !important;
  width: fit-content;
  height: fit-content;
  background-color: rgba(255, 255, 255, 0.448);
  border: 1px solid rgba(255, 255, 255, 0);
  padding: 5px;
  top: 0px;
  border-radius: 15px;
  box-shadow: 1px 1px 4px 1px rgba(255, 255, 255, 0.956);
}

.login-div {
  position: relative;
  margin: 0px auto !important;
  left: 0;
  right: 0;
  width: 350px;
  border-radius: 8px;
  padding: 8px;
  padding-top: 8px;
  padding-bottom: 25px;
  text-align: left;
  box-shadow: 0px 2px 4px 1px lightgray;
  background-color: rgba(255, 255, 255, 0.826);
}

.inner-div {
  padding: 0px;
}

.login-form {
  position: relative;
  display: block;
  width: calc(100% - 50px);
  margin: 0 auto !important;
  top: 0px;
  padding: 15px;
}
.login-form .form-control {
  padding: 5px;
  font-size: 18px;
  font-weight: lighter;
  margin-top: 4px;
  margin-bottom: 4px;
  width: calc(100% - 10px);
}

.logo {
  position: relative;
  display: block;
  margin: 0px auto !important;
  position: relative;
  top: 0px;
  width: 80px;
  padding: 0px;
}
.help,
.forgotpass {
  text-decoration: none;
  color: var(--btn-info-dark);
}

.help {
  position: relative;
  text-align: right;
  float: right;
}

label {
  position: relative;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
  display: block;
}

.btn {
  display: block;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
}

.errmsg {
  position: relative;
  display: block;
  text-align: center;
  padding: 3px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
}
.dangermsg {
  background-color: #f12b08f2;
  color: white;
}
.warnmsg {
  background-color: #ebca12;
  color: white;
}
.donemsg {
  background-color: #05bb23;
  color: white;
}

.devcontact {
  position: relative;
  display: block;
  text-align: center;
  margin-top: 15px;
}
.devcontact a {
  font-size: 15px;
  font-weight: bold;
  color: gray;
}
.font-18 {
  font-size: 18px;
}
.font-16 {
  font-size: 16px;
}

.devlogo {
  position: relative;
  display: block;
  width: 55px;
  margin: 0px auto !important;
}

@media only screen and (max-width: 600px) {
  .outer-div {
    top: 0px;
  }
}
@media only screen and (max-width: 400px) {
  .outer-div {
    top: 0px;
  }
  .login-div {
    width: 310px;
  }

  .inner-div {
    height: fit-content;
  }

  .logo {
    position: relative;
    display: block;
    margin: 0px auto !important;
    position: relative;
    top: 0px;
    width: 65px;
    padding: 0px;
  }
  .help,
  .forgotpass {
    position: relative;
    display: inline-block;
    font-size: 14px;
  }
}
</style>
